<template>
  <div>
    保存草稿
    返回首页
  </div>
</template>

<script>

export default {

}
</script>

<style>

</style>